var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"History"}},[_c('v-container',[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v("Message History")]),_c('v-spacer'),(_vm.instance&&_vm.instance.isInstanceUp)?_c('v-btn',{staticClass:"black white--text mr-1",attrs:{"outlined":""},on:{"click":_vm.getMessages}},[_vm._v(" Get History ")]):_vm._e(),_c('v-btn',{staticClass:"black white--text",attrs:{"text":"","outlined":""},on:{"click":function($event){return _vm.$router.replace('/')}}},[_vm._v("GoTo Dashboard")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.messages,"headers":_vm.headers,"height":"620","items-per-page":50},scopedSlots:_vm._u([{key:"item.msg_obj",fn:function(ref){
var item = ref.item;
return [(item.msg_obj.hasOwnProperty('extendedTextMessage'))?_c('div',[_vm._v(" "+_vm._s(item.msg_obj.extendedTextMessage.text)+" ")]):(item.msg_obj.hasOwnProperty('imageMessage'))?_c('div',[_c('img',{attrs:{"width":"30","src":'data:'+item.msg_obj.imageMessage.mimetype+';base64,'+item.msg_obj.imageMessage.jpegThumbnail}}),_vm._v(" "+_vm._s(item.msg_obj.imageMessage.caption)+" ")]):(item.msg_obj.hasOwnProperty('videoMessage'))?_c('div',[_c('img',{attrs:{"height":"30","src":'data:'+item.msg_obj.videoMessage.mimetype+';base64,'+item.msg_obj.videoMessage.jpegThumbnail}}),_vm._v(" "+_vm._s(item.msg_obj.videoMessage.caption)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.msg_obj)+" ")])]}},{key:"item.sent_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.sent_time))+" ")]}},{key:"item.delivered_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.delivered_time))+" ")]}},{key:"item.seen_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.seen_time))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getStatusIconColor(item.status)}},[_vm._v("mdi-"+_vm._s(_vm.getStatusIcon(item.status)))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }