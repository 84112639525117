<template>
  <div id="History">
    <v-container>
      <v-card>
        <v-card-title>
          <div>Message History</div>
          <v-spacer></v-spacer>
          <v-btn @click="getMessages" v-if="instance&&instance.isInstanceUp" class="black white--text mr-1" outlined>
            Get History
          </v-btn>
          <v-btn @click="$router.replace('/')" class="black white--text" text outlined>GoTo Dashboard</v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table :items="messages" :headers="headers" height="620" :items-per-page="50">
            <template v-slot:item.msg_obj="{ item }">
              <div v-if="item.msg_obj.hasOwnProperty('extendedTextMessage')">
                {{ item.msg_obj.extendedTextMessage.text }}
              </div>
              <div v-else-if="item.msg_obj.hasOwnProperty('imageMessage')">
                <img width="30"
                     :src="'data:'+item.msg_obj.imageMessage.mimetype+';base64,'+item.msg_obj.imageMessage.jpegThumbnail">
                {{ item.msg_obj.imageMessage.caption }}
              </div>
              <div v-else-if="item.msg_obj.hasOwnProperty('videoMessage')">
                <img height="30"
                     :src="'data:'+item.msg_obj.videoMessage.mimetype+';base64,'+item.msg_obj.videoMessage.jpegThumbnail">
                {{ item.msg_obj.videoMessage.caption }}
              </div>
              <div v-else>
                {{ item.msg_obj }}
              </div>
            </template>
            <template v-slot:item.sent_time="{ item }">
              {{ formatDate(item.sent_time) }}
            </template>
            <template v-slot:item.delivered_time="{ item }">
              {{ formatDate(item.delivered_time) }}
            </template>
            <template v-slot:item.seen_time="{ item }">
              {{ formatDate(item.seen_time) }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon :color="getStatusIconColor(item.status)">mdi-{{ getStatusIcon(item.status) }}</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { EventBus } from '@/plugins/EventBus'

export default {
  name: 'History',
  props: { url: { type: [String], default: null } },
  data () {
    return {
      instance: null, instanceStatus: {}, messages: [],
      headers: [
        { text: 'TO', value: 'to_jid' },
        { text: 'Sent Time', value: 'sent_time' },
        { text: 'Delivered Time', value: 'delivered_time' },
        { text: 'Seen Time', value: 'seen_time' },
        { text: 'Status', value: 'status' },
        { text: 'Content', value: 'msg_obj' },
      ],
    }
  },
  mounted () {
    this.instance = this.$store.getters.instance
    EventBus.$on('instanceUpdate', instance => {
      this.instance = instance
      this.$forceUpdate()
    })
    this.instanceStatus = this.$store.getters.instanceStatus
    EventBus.$on('instanceStatusUpdate', instance => {
      this.instanceStatus = instance
      this.$forceUpdate()
    })
  },
  methods: {
    getMessages () {
      axios.post(`${this.url}getMessageHistory`, { instanceId: this.instance.instance_id }).
          then(res => { this.messages = res.data.messages })
    },
    formatDate (date) {
      return date ? moment(date).format('DD/MM/YYYY hh:mm:ss') : '-'
    },
    getStatusIcon (status) {
      if (status === 1) return 'check'
      else if (status === 2) return 'check-all'
      else if (status === 3) return 'check-all'
      else return ''
    }, getStatusIconColor (status) {
      if (status === 1) return 'grey'
      else if (status === 2) return 'grey'
      else if (status === 3) return 'blue'
    },
  },
}
</script>
